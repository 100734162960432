import React from 'react'
import ComingSoonPage from './pages/ComingSoonPage'

export default function App() {
  return (
    <div>
        <ComingSoonPage />
    </div>
  )
}
