import React from 'react'
import cover from '../../public/img/cover.webp'
import logo from '../../public/svg/logo.svg'

const ComingSoonPage = () => {
  return (
    <div
      className="bg-cover bg-center h-screen flex justify-center items-center"
      style={{ backgroundImage: `url(${cover})` }}
    >
      <div className="bg-slate-50/30 p-40 rounded-2xl backdrop-blur-lg">
        <img src={logo} alt="logo" className="w-64 mx-auto" />
        <h1 className="mt-16 text-4xl text-center font-bold text-white">Coming Soon</h1>
      </div>
    </div>
  );
};

export default ComingSoonPage;